import * as React from 'react';
import Service from '../Networking/networkutils';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import LookupHelper from '../Common/LookupHelper';
import { getCookie } from '../Common/CookieHelper';

const token = getCookie("bb_ts_token");



class User extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeusers: [],
            inactiveusers: [],
            isShowTableLoading: true,
            usersEmails: {},
            defaultSlots: {},
        };
    }
    componentDidMount() {
        Promise.all([this.getUserEmailData(), this.getDefaultSlotData(), this.getUserData()])
            .then(([usersEmails, defaultSlots, users]) => {

            })
            .catch(error => {

            })
            .finally(() => {
                this.setState({ isShowTableLoading: false });
            });
    }

    componentWillUnmount() {

    }

    getUserEmailData() {
        // var emails = {};
        const lookupObj = {};
        Service.get('/bb/api/users/?office_loc=1', {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                // if (res.data.length > 0) {
                //     console.log(res.data)
                //     let _emails = res.data.map((d) => { return { id: d.id, email: d.email } });
                //     emails = LookupHelper.getLookupByData(_emails, 'id', 'email');
                // }

                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    var id = element.id;
                    var username = element.last_name + ", " + element.first_name;
                    lookupObj[id] = username;
                }
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
            })
            .finally(() => {
                this.setState({ usersEmails: lookupObj });
            });
    }

    getDefaultSlotData() {
        var defaultSlots = {};
        Service.get('/bb/api/pms/parking/details/?vehicle_type=4&is_active=true', {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                if (res.data.length > 0) {
                    let slots = res.data.map((d) => { return { id: d.id, parking_name: d.parking_name } });
                    var slots1 = [...[{ id: null, parking_name: 'None' }], ...slots]
                    defaultSlots = LookupHelper.getLookupByData(slots1, 'id', 'parking_name');
                }
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
            })
            .finally(() => {
                this.setState({ defaultSlots: defaultSlots });
            });
    }

    getUserData() {
        var data = [];
        var data1 = []
        Service.get('/bb/api/pms/user/details/', {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    const ele = res.data[i]
                    if (ele.is_active) {
                        data.push(ele)
                    } else {
                        data1.push(ele)
                    }
                }
                this.setState({ activeusers: data, inactiveusers: data1, isShowTableLoading: false });
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
            })

    }

    insertUsers = (newData, resolve) => {
        var exist_in_table = false;
        var exist_in_archieve = false
        const user = JSON.parse(getCookie("bb_ts_user"))
        const User_Id = user.id
        newData.created_by = User_Id;
        var activeusers = this.state.activeusers;
        var inactiveusers = this.state.inactiveusers;

        for (let i = 0; i < activeusers.length; i++) {
            const ele = activeusers[i]
            if (ele.user_data.id === parseInt(newData.uid)) {
                exist_in_table = true
            }
        }
        for (let j = 0; j < inactiveusers.length; j++) {
            const ele1 = inactiveusers[j]
            if (ele1.user_data.id === parseInt(newData.uid)) {
                exist_in_archieve = true
            }
        }
        if (exist_in_table) {
            this.props.enqueueSnackbar('User Already Exits in the Table!', { variant: 'warning' });
            resolve();
        } else if (exist_in_archieve) {
            this.props.enqueueSnackbar('User Exits in Archieve.Please set the User status to Active', { variant: 'warning' });
            resolve();
        } else {
            Service.post('/bb/api/pms/user/details/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: newData
            })
                .then(res => {
                    this.props.enqueueSnackbar('Data saved successfully!', { variant: 'success' });
                    this.getUserData();
                })
                .catch(error => {
                    this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                })
                .finally(() => {
                    resolve();
                });
        }

    }
    updateUsers = (newData, oldData, resolve) => {
        const user = JSON.parse(getCookie("bb_ts_user"))
        const User_Id = user.id
        newData.created_by = User_Id;
        Service.put('/bb/api/pms/user/details/' + newData.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: newData
        })
            .then(res => {
                this.props.enqueueSnackbar('Data updated successfully!', { variant: 'success' });
                this.getUserData();
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
            })
            .finally(() => {
                resolve();
            });

    }

    deleteUsers = (oldData, resolve) => {
        Service.delete(`/bb/api/pms/parking/allotment/unused_deletion/?id=${oldData.id}`, {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                this.props.enqueueSnackbar('Data deleted successfully!', { variant: 'success' });
                this.getUserData();
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
            })
            .finally(() => {
                resolve();
            });
    }

    render() {
        return (
            <div>
                <MaterialTable
                    title="Active Users"
                    columns={[
                        {
                            title: 'Full Name', field: 'uid',
                            lookup: this.state.usersEmails
                        },
                        { title: 'Vehicle No', field: 'vehicle_number' },
                        {
                            title: 'Status', field: 'is_active', editable: 'never',
                            lookup: { true: "Active", false: 'Inactive' }
                        },
                        {
                            title: 'Auto Booking', field: 'auto_booking', editable: 'never',
                            lookup: { true: "Enable", false: 'Disable' }
                        },
                        { title: 'Default Priority', field: 'priority_ratio', defaultSort: 'desc', type: 'numeric' },

                        { title: 'Default Slot', field: 'parking_details', type: 'numeric', lookup: this.state.defaultSlots },
                        { title: 'Telegram UserName', field: 'telegram_username' },
                    ]}
                    data={this.state.activeusers}
                    options={{
                        actionsColumnIndex: -1,
                        filtering: true,
                        headerStyle: {
                            backgroundColor: '#007681',
                            color: '#FFF'
                        },
                        paging: false,
                        pageSize: 20
                    }}

                    isLoading={this.state.isShowTableLoading}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve) => {
                                this.updateUsers(newData, oldData, resolve);
                            }),
                        onRowAdd: (newData) =>
                            new Promise((resolve) => {
                                this.insertUsers(newData, resolve);
                            }),
                        onRowDelete: (oldData) =>
                            new Promise((resolve) => {
                                this.deleteUsers(oldData, resolve)
                            })
                    }}
                />
                <MaterialTable style={{ marginTop: '20px' }}
                    title="Archieve Users"
                    columns={[
                        {
                            title: 'Full Name', field: 'uid',
                            lookup: this.state.usersEmails
                        },
                        { title: 'Vehicle No', field: 'vehicle_number' },
                        {
                            title: 'Status', field: 'is_active',
                            lookup: { true: "Active", false: 'Inactive' }
                        },

                        {
                            title: 'Auto Booking', field: 'auto_booking',
                            lookup: { true: "Enable", false: 'Disable' }
                        },
                        { title: 'Default Priority', field: 'priority_ratio', type: 'numeric' },
                        { title: 'Default Slot', field: 'parking_details', type: 'numeric', lookup: this.state.defaultSlots }
                    ]}
                    data={this.state.inactiveusers}
                    options={{
                        actionsColumnIndex: -1,
                        filtering: true,
                        headerStyle: {
                            backgroundColor: '#007681',
                            color: '#FFF'
                        },
                        paging: false,
                        pageSize: 20
                    }}

                    isLoading={this.state.isShowTableLoading}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve) => {
                                this.updateUsers(newData, oldData, resolve);
                            }),
                        // onRowAdd: (newData) =>
                        //     new Promise((resolve) => {
                        //         this.insertUsers(newData, resolve);
                        //     }),
                        // onRowDelete: (oldData) =>
                        //     new Promise((resolve) => {
                        //         this.deleteUsers(oldData, resolve)
                        //     })
                    }}
                />
            </div >
        );
    }
}
export default (withSnackbar(User));